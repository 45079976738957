import { useEffect, useState } from "react";
import { useUser, User } from "./useUser";
import { useLocalStorage } from "./useLocalStorage";
import { useToken } from "./useToken";

export const useAuth = () => {
  const { token, addToken, removeToken, setToken } = useToken();
  const { getItem } = useLocalStorage();
  const [ loaded, setLoaded] = useState(false);

  useEffect(() => {
    const token = getItem("token");
    if (token) {
      addToken(token);
    }
    setLoaded(true);
  }, []);

  const authenticate = (result) => {
    addToken(result.token);
  };

  const logout = () => {
    removeToken();
  };

  return { token, authenticate, logout, setToken, loaded };
};