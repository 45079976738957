import { Switch } from "@mui/material";
import SortButton from "components/Headers/SortButton";
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Collapse,
  FormGroup,
  InputGroup,
  Input,
  Col,
  Row,
  Table,
} from "reactstrap";
import Enabler from "../components/Enabler";

class Units extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedCollapses: ["collapseOne"]
    };
  }

  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: []
      });
    } else {
      this.setState({
        openedCollapses: [collapse]
      });
    }
  };
  render() {

    const { units, handleClickSort, handleUpdateUnit } = this.props;

    return (
      <>
        <div className="accordion">
          <Card className="card-plain">
            <Collapse
              role="tabpanel"
              isOpen={this.state.openedCollapses.includes("collapseOne")}
            >
              <CardBody>
                <Row>
                    <Col xl={12}>
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                            <tr>
                                <th scope="col">
                                  Unit Name <SortButton onClick={(direction) => handleClickSort(direction, 'Name')} />
                                </th>
                                <th scope="col">
                                  External Code <SortButton onClick={(direction) => handleClickSort(direction, 'ExternalUnitId')} />
                                </th>
                                <th scope="col">
                                  Internal Code <SortButton onClick={(direction) => handleClickSort(direction, 'TuyaUnitId')} />
                                </th>
                                <th scope="col">
                                  Enabled <SortButton onClick={(direction) => handleClickSort(direction, 'Active')} />
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {units ? units.map((i) => {
                                const color = i.active === true ? 'default' : 'warning';
                                return <tr key={`unit_${i.id}`}>
                                  <th scope="row">{i.name}</th>
                                  <td>{i.externalUnitId}</td>
                                  <td>{i.tuyaUnitId}</td>
                                  <td>
                                    <Enabler 
                                        unitId={i.id} 
                                        active={i.active} 
                                        onChange={handleUpdateUnit} 
                                        integrationType={i.integrationType}
                                      />
                                  </td>
                                </tr>
                            }) : null}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
              </CardBody>
            </Collapse>
          </Card>
        </div>
      </>
    );
  }
}

export default Units;