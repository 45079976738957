export const HardcodedPartners = [
    {
        id: "9b1deb4d-6b3b-4c8e-850b-6c5a87f7a9c2",  // Sample GUID
        name: "Orlando Homes",
        integrationType: 'Track'
    },
    {
        id: "9b1deb4d-6b3b-4c8e-850b-6c5a87f7a9c2",  // Sample GUID
        name: "Lovely Stays.Com",
        integrationType: 'Ciirus'
    },
    // Additional Partner objects can go here
];
