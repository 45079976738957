/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.easyhub.ai/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.easyhub.ai)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import CardsHeader from "components/Headers/CardsHeader.js";

import {
  chartOptions,
  parseOptions,
} from "variables/charts.js";
import { HardcodedPartners } from "variables/clients";
import { searchForPartners } from "actions/partner-actions";
import { PartnerContext } from "contexts/partner-context";
import { useAuth } from "hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { isNotNullOrEmpty } from "util/null-helper";
import { listPartnerStatistics } from "actions/statistic-actions";

function Partners() {
  const partnerContext = React.useContext(PartnerContext);
  const { partnerSearchResult, setPartnerSearchResult, setPartner } = partnerContext;
  const [partnersStatistics, setPartnersStatistic] = React.useState([]);

  const { token, loaded } = useAuth();
  const navigate = useNavigate();
  const pageSize = 15;
  const pageNumber = 0;
  const partnerId = null;

  const loadPartners = async () => {
    const response = await searchForPartners(partnerId, pageSize, pageNumber);
    setPartnerSearchResult(response.data);
  }

  const loadPartnerStatistics = async () => {
    const response = await listPartnerStatistics();
    setPartnersStatistic(response.data);
  }

  const onPageLoad = async () => {
    if(loaded === true) {
      await Promise.all([loadPartners(), loadPartnerStatistics()]);
    }
  }

  useEffect(() => {
    onPageLoad();
  }, [loaded]);

  const handleClickPartner = (e, partner) => {
    e.preventDefault();
    setPartner(partner);
    navigate(`/admin/partners/${partner.id}`)
  }

  const getStatistic = (partnerId, getValue) => {
    const partner = partnersStatistics.filter((val) => val.partnerId === partnerId)[0];

    return partnersStatistics.length > 0 && isNotNullOrEmpty(partner)
      ? getValue(partner)
      : 0;
  }

  return (
    <>
      <CardsHeader name="Management" parentName="Clients" />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Client name</th>
                    <th scope="col">Enabled</th>
                    <th scope="col">Active Units</th>
                    <th scope="col">Inactive Units</th>
                    <th scope="col">Reservations</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {partnerSearchResult ? partnerSearchResult.items.map((i) => {
                    return <tr key={`partner_${i.id}`}>
                      <th scope="row">{i.name}</th>
                      <td>{i.enabled === true ? "Yes" : "No"}</td>
                      <td>{getStatistic(i.id, (statistic) => statistic ? statistic.totalActiveUnits : 0)}</td>
                      <td>{getStatistic(i.id, (statistic) => statistic ? statistic.totalInactiveUnits : 0)}</td>
                      <td>{getStatistic(i.id, (statistic) => statistic ? statistic.totalReservations : 0)}</td>
                      <td className="table-actions">
                        <a
                          className="table-action"
                          href="#pablo"
                          id="tooltip183793484"
                          onClick={(e) => handleClickPartner(e, i)}
                        >
                          <i className="fas fa-edit" />
                        </a>
                        <a
                          className="table-action table-action-delete"
                          href="#pablo"
                          id="tooltip12475020"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fas fa-trash" />
                        </a>
                      </td>
                    </tr>
                  }) : null}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Partners;
