import { ThreeDots } from "react-loader-spinner";

export default function Loader({ height, width, color }){
    return <ThreeDots
        visible={true}
        height={height}
        width={width}
        color={color}
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
        style={{ position: "absolute" }}
    />
}