import axios from "axios"
import { createClient } from "util/axios-helpers";
import { getQueryStringParams } from "util/http-helpers";
import { endpoint } from "util/http-helpers";

export const searchForUnits = (partnerId, name, pageSize, pageIndex, sortBy) => {

    const client = createClient(axios);

    const params = getQueryStringParams({
        partnerId,
        name,
        pageSize,
        pageNumber: pageIndex,
        sortBy
    });

    return client.get(endpoint(`api/v1/units?${params}`));
}


export const enableUnits = (unitIds, isEnabled, integrationType) => {

    const client = createClient(axios);

    return client.put(endpoint(`api/v1/units`), {
        unitIds,
        isEnabled,
        integrationType
    });
}