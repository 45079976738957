import MUIPagination from '@mui/material/Pagination';

const { Row, Col, Container } = require("reactstrap");

export default function Pagination({ totalPages, onChange, page, float }) {

    return (
      <>
      <div style={{ float: float}}>
          <MUIPagination 
            count={totalPages} 
            color="primary" 
            onChange={onChange}
            page={page}
          />
      </div>
      </>
    );
}