import axios from "axios"
import { createClient } from "util/axios-helpers";
import { getQueryStringParams } from "util/http-helpers";
import { endpoint } from "util/http-helpers";

export const searchForPartners = (partnerId, pageSize, pageIndex, sortBy) => {
    const client = createClient(axios);

    const params = getQueryStringParams({
        partnerId,
        pageSize,
        pageNumber: pageIndex,
        sortBy
    });

    return client.get(endpoint(`api/v1/partners?${params}`));
}