import { useState } from 'react';
import './SortButton.css';

export default function SortButton({ onClick }){
    const [direction, setDirection] = useState('+');

    const handleClick = () => {
        const newDirection = direction === '-' ? '+' : '-';
        setDirection(newDirection);
        onClick(newDirection);
    }

    return <i 
        className={`fa fa-arrow-${direction === "-" ? "up" : "down"} sort-button`} 
        onClick={handleClick} 
    />
}