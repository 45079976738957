import { getAccessToken } from "./storage-helpers";

export const createClient = (axios) => {
    axios.interceptors.request.use(
        config => {
            const token = getAccessToken()
            if (token) {
                config.headers['x-access-token'] = token;
                config.headers['x-functions-key'] = process.env.REACT_APP_CORE_API_FUNCTION_KEY;
            }
            // config.headers['Content-Type'] = 'application/json';
            return config
        },
        error => {
            Promise.reject(error)
        }
    );
    axios.interceptors.response.use(
        response => {
            return response
        },
        function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                window.location = '/auth/login';
                return Promise.reject(error)
            }
        
            return Promise.reject(error)
        }
    );

    return axios;
}