import { useContext, useState } from "react";
import { AppContext } from "../contexts/auth-context";
import { useLocalStorage } from "./useLocalStorage";


export const useSnack = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");

  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);

  return { isOpen, onOpen, onClose, message, setMessage };
};
