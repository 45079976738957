import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import ReactApexChart from 'react-apexcharts';
import { isNotNullOrEmpty } from "util/null-helper";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import CustomSkeleton from "components/Skeleton/CustomSkeleton";
import { arrayNotNullAndHasEntries } from "util/null-helper";
import Loader from "components/Loader/Loader";
import { blue } from "@mui/material/colors";
import './chart.css';  // Import your custom CSS
import PartnerSelect from "views/pages/components/PartnerSelect";

export default function GeneralStatistic({ statistics, loading, partners, onPartnerChange }) {
    const getStatistic = (statistic, mapValue) => {
        return isNotNullOrEmpty(statistic) ? mapValue(statistic) : null;
    }

    const skeletons = [1];

    if (!arrayNotNullAndHasEntries(statistics)) {
        return skeletons.map((x, i) => (
            <Col xl={12} key={i}>
                <Card style={{ minHeight: 600 }}>
                    <CardHeader className="bg-transparent">
                        <Row className="align-items-center">
                            <Col xl={9}>
                                <h6 className="text-light text-uppercase ls-1 mb-3">Statistics</h6>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <div className="d-flex justify-content-center mb-5 mt-5">
                            <Loader height={40} width={40} color={blue[500]} />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        ));
    }

    // Process data for grouped bar chart with two groups per partner name
    const categories = statistics.flatMap((item) => [
        `${getStatistic(item, (statistic) => statistic.partnerName)}`,
    ]);

    const seriesReservations = ['Total Reservations', 
        'Pending Check-In', 
        'Checked-In', 
        'Checked-Out',
        'Total Units', 
        'Active Units', 
        'Inactive Units'
    ].map((metric, index) => ({
        name: metric,
        data: statistics.map((item) => getStatistic(item, (statistic) => [
            statistic.totalReservations,
            statistic.totalReservationsPendingCheckIn,
            statistic.totalReservationsCheckedIn,
            statistic.totalReservationsCheckedOut,
            statistic.totalUnits,
            statistic.totalActiveUnits,
            statistic.totalInactiveUnits
        ][index]) || 0)
    }));

    const options = {
        chart: {
            type: 'bar',
            background: 'transparent',
            stacked: false
        },
        title: {
            text: 'Reservations and Units Statistics',
            align: 'center'
        },
        xaxis: {
            categories: categories,
            labels: {
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    colors: '#000'
                }
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: false,
                grouped: true
            }
        },
        fill: {
            type: 'solid',
            opacity: 1
        },
        tooltip: {
            theme: 'dark',
            style: {
                fontSize: '12px',
                fontFamily: undefined
            },
            y: {
                formatter: function (val) {
                    return val;
                }
            }
        },
        legend: {
            position: 'top',
            horizontalAlign: 'center'
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: '100%'
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }
        ]
    };

    return (
        <Col xl="12">
            <Card>
                <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                        <Col xl={10}>
                            <h6 className="text-light text-uppercase ls-1 mb-3">Statistics</h6>
                        </Col>
                        <Col xl={2} align="right">

                            <PartnerSelect
                                partners={partners}
                                onChange={onPartnerChange}
                            />
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <div className="chart-container">
                        {loading === false ? (
                            <ReactApexChart
                                options={options}
                                series={[...seriesReservations]}
                                type="bar"
                                height={400}
                            />
                        ) :
                        <div className="d-flex justify-content-center mb-5 mt-5" style={ { minHeight: '320px'}}>
                            <Loader height={40} width={40} color={blue[500]} />
                        </div>}
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
}
