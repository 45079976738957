/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.easyhub.ai/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.easyhub.ai)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import CardsHeader from "components/Headers/CardsHeader.js";

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";
import { searchForUnits } from "actions/unit-actions";
import { useAuth } from "hooks/useAuth";
import { searchForReservations } from "actions/reservation-actions";
import { listGeneralStatistics } from "actions/statistic-actions";
import { isNotNullOrEmpty } from "util/null-helper";
import GeneralStatistic from "./cards/GeneralStatistic";
import ReservationsOverTime from "./cards/ReservationsOverTime";
import { Skeleton } from "@mui/material";
import { searchForPartners } from "actions/partner-actions";

function Dashboard() {
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");
  const [unitSearchResult, setUnitSearchResult] = React.useState(null);
  const [generalStatistics, setGeneralStatistics] = React.useState([]);
  const [reservationSearchResult, setReservationSearchResult] = React.useState(null);
  const [partners, setPartners] = React.useState([]);
  const [loadingStatistics, setLoadingStatistics] = React.useState(false);
  const { loaded } = useAuth();
  const pageIndex = 0;
  const pageSize = 1;
  const sortBy = null;
  const partnerId = null;

  const loadUnits = async () => {
    const response = await searchForUnits(partnerId, null, pageSize, pageIndex, sortBy);

    setUnitSearchResult(response.data);
  }
  
  const loadReservations = async () => {
    const response = await searchForReservations(partnerId, pageSize, pageIndex, sortBy);

    setReservationSearchResult(response.data);
  }

  const loadGeneralStatistics = async (partnerId) => {
    setLoadingStatistics(true);
    const response = await listGeneralStatistics(partnerId);
    setGeneralStatistics(response.data);
    setLoadingStatistics(false);
  }

  const loadPartners = async () => {
    const response = await searchForPartners(null, 200, 0);
    setPartners(response.data.items);
  }

  const handlePartnerChange = async (partnerId) => {
    await loadGeneralStatistics(partnerId);
  }

  React.useEffect(() => {
    if(loaded === true) {
      loadPartners();
      loadUnits();
      loadReservations();
      loadGeneralStatistics(null);
    }
  }, [loaded]);

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  };
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  return (
    <>
      <CardsHeader name="Default" parentName="Dashboards" />
      <Container className="mt--6" fluid>
        <Row>
          <GeneralStatistic 
            statistics={generalStatistics} 
            loading={loadingStatistics} 
            onPartnerChange={handlePartnerChange}
            partners={partners}
          />
        </Row>
        <Row>
          <ReservationsOverTime statistics={generalStatistics} loading={loadingStatistics}  />
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
