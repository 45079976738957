import axios from "axios"
import { cache } from 'react';
import { createClient } from "util/axios-helpers";
import { httpHeadersWithAuthorization } from "util/http-helpers";
import { endpoint } from "util/http-helpers";
import { DEFAULT_HTTP_HEADERS } from "util/http-helpers";

export const login = ({ username, password }) => {
    const client = createClient(axios);

    return client.post(endpoint('api/v1/users/login'), { 
        username,
        password
    });
}

export const getUserDetails = ()=> {
    const client = createClient(axios);

    return client.get(endpoint(`api/v1/me/user`));
};