import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Loader from 'components/Loader/Loader';
import { blue } from '@mui/material/colors';

export default function PartnerSelect({ partners, onChange }) {
    const [partnerId, setPartnerId] = useState('ALL');
    const [isLoading, setIsLoading] = useState(false);

    // Handle the change in selected partner
    const handleChange = async (event) => {
        setIsLoading(true);
        try {
            const selectedId = event.target.value;

            setPartnerId(selectedId);
            if (onChange) {
                await onChange(selectedId === 'ALL' ? null : selectedId);
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading(false);
    };

    return (
        <FormControl fullWidth>
            {!isLoading && <InputLabel id="partner-select-label">Select Partner</InputLabel>}
            {!isLoading ? (
                <Select
                    labelId="partner-select-label"
                    id="partner-select"
                    value={partnerId} // Ensure 'ALL' is correctly handled
                    onChange={handleChange}
                    sx={ { textAlign: 'left' }}
                    label="Select Partner"
                >
                    <MenuItem key="All" value="ALL">
                        All Partners
                    </MenuItem>
                    {partners.map((partner) => (
                        <MenuItem key={partner.id} value={partner.id}>
                            {partner.name}
                        </MenuItem>
                    ))}
                </Select>
            ) : (
                <Box>
                    <div style={{ float: 'right', marginRight: '10px' }}>
                        <Loader height={40} width={40} color={blue[500]} />
                    </div>
                </Box>
            )}
        </FormControl>
    );
}