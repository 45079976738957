import { useState } from "react";
import { AuthContext } from "./auth-context"
import { PartnerContext } from "./partner-context"
import { ReservationContext } from "./reservation-context"
import { UnitContext } from "./unit-context"
import { login } from "actions/user-actions";

export const AdminContext = ({ children, user }) => {

    const [partnerSearchResult, setPartnerSearchResult] = useState(null);
    const [partner, setPartner] = useState(null);

    const [unitSearchResult, setUnitSearchResult] = useState(null);
    
    const [reservationSearchResult, setReservationSearchResult] = useState(null);

    return  <>    
        <AuthContext.Provider value={ { user: user, login: login }}>
            <PartnerContext.Provider value={ { partnerSearchResult, setPartnerSearchResult, partner, setPartner }}>
                <UnitContext.Provider value={ { unitSearchResult, setUnitSearchResult }}>
                    <ReservationContext.Provider value={ { reservationSearchResult, setReservationSearchResult }}>
                        {children}
                    </ReservationContext.Provider>
                </UnitContext.Provider>
            </PartnerContext.Provider>
        </AuthContext.Provider>
    </>

}