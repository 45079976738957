import { useContext, useState } from "react";
import { AppContext } from "../contexts/auth-context";
import { useLocalStorage } from "./useLocalStorage";


export const useToken = () => {
  const [token, setToken] = useState("");
  const { setItem } = useLocalStorage();

  const addToken = (token) => {
    setToken(token);
    setItem("token", token);
  };

  const removeToken = () => {
    setToken(null);
    setItem("token", "");
  };

  return { token, addToken, removeToken, setToken };
};
