import { SearchOutlined } from "@mui/icons-material";
import { Box, FormControl, IconButton, InputAdornment, InputLabel, Modal, OutlinedInput, Snackbar, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import Loader from "components/Loader/Loader";
import { useSnack } from "hooks/useSnack";
import { useState } from "react";
import { Form } from "reactstrap";
import { isNullOrEmpty } from "util/null-helper";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  
export default function QuickSearch({ onSearchReservation, theme, classnames, closeSearch }){
    const [externalId, setExternalId] = useState('');
    const [reservation, setReservation] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showReservation, setShowReservation] = useState(false);
    const snack = useSnack();

    const handleClickSearch = async () => {
        setIsLoading(true);
        
        if(isNullOrEmpty(externalId) === true) {
            return;
        }

        try {
            var response = await onSearchReservation(externalId);
            setShowReservation(true);
            setReservation(response.data);
        } catch(err) {
            snack.setMessage(`Unable to find Reservation ${externalId}`);
            snack.onOpen();
        }
        setIsLoading(false);
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        await handleClickSearch();
    }

    const onCloseModal = () => {
        setShowReservation(false);
        setReservation(null);
    }

    return <>
      <Form
            className={classnames(
            "navbar-search form-inline mr-sm-3",
            { "navbar-search-light": theme === "dark" },
            { "navbar-search-dark": theme === "light" }
            )}
            onSubmit={handleFormSubmit}
        >
        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" fullWidth>
            <InputLabel htmlFor="quick-search">Quick search</InputLabel>
            <OutlinedInput 
                id="quick-search" 
                type="text" 
                onChange={(e) => setExternalId(e.target.value)} 
                value={externalId}
                sx={{ backgroundColor: '#fff' }}
                endAdornment={
                    <InputAdornment position="end">
                        {isLoading === false ? <IconButton
                            aria-label={
                            'Search'
                            }
                            onClick={handleClickSearch}
                        >
                        <SearchOutlined />
                    </IconButton> : <Loader height={40} width={40} color={blue[500]} />}
                    </InputAdornment>
                }
                label="Search"
            />
    </FormControl>
        <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={closeSearch}
            >
            <span aria-hidden={true}>×</span>
        </button>
    </Form>
    {reservation !== null ? <Modal
        open={showReservation}
        onClose={() => onCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Reservation #{reservation.externalReservationId}
            </Typography>
            <Typography id="modal-modal-description" variant="h6" component="h2" style={ { marginTop: '10px'}}>
                {reservation.roomNo}
            </Typography>
            <Typography id="modal-modal-description" variant="h6" component="h2" style={ { marginTop: '10px'}}>
                <b>Status</b> {reservation.status}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <b>IntergrationType</b> {reservation.integrationType}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <b>Arrival Date</b> {reservation.arrivalDate} {reservation.arrivalDateTime}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <b>Departure Date</b> {reservation.departureDate} {reservation.departureDateTime}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <b>Guest</b> {reservation.contactFirstName} {reservation.contactLastName}
            </Typography>
            <Box>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <b>Locker Password</b> {reservation.lockerPassword}
                </Typography>
            </Box>
        </Box>
    </Modal> : null}
    <Snackbar
        open={snack.isOpen}
        autoHideDuration={6000}
        onClose={snack.onClose}
        message={snack.message}
    />
    </>
}